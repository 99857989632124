//@ts-nocheck
import LocalStorageService from 'services/LocalStorageService';
import { add } from 'utils/timeUtils';

const LOCAL_STORAGE_KEY = {
    PURCHASE_STORE: 'GTMServicePurchaseStore'
};

const EVENT = {
    ADD_ECOMMERCE: 'addEcommerce',
    AUTHORIZATION: 'authorization'
};

const FUNNEL = {
    NON_INTERACTIONS: 'Non-Interactions'
};

const STEP = {
    /**
     * Покупка
     */
    PURCHASE: 'purchase'
};

export const PARAM = {
    /**
     * В ИНВИТРО
     */
    OFFICE: 'v invitro',

    /**
     * Выезд на дом
     */
    VND: 'VND'
};

export const PAGE = {
    THANK_YOU: 'ThankYouPage'
};

const AFFILIATION = {
    LK3: 'lk3'
};

class GTMService {
    authorization() {
        return {
            push: ({ event = 'YM_event', event_cat = EVENT.AUTHORIZATION, event_id, action, event_param }) => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event,
                    event_cat,
                    event_id,
                    action,
                    event_param
                });
            }
        };
    }

    purchase(page, param, order, products) {
        let store = [];
        if (LocalStorageService.getItem(LOCAL_STORAGE_KEY.PURCHASE_STORE)) {
            store = JSON.parse(LocalStorageService.getItem(LOCAL_STORAGE_KEY.PURCHASE_STORE) || []);
            store = store.filter((item) => Date.now() > item.expiry);
        }

        if (!store.find((item) => item.id === order.id)) {
            new DataLayer(FUNNEL.NON_INTERACTIONS, STEP.PURCHASE, param, page, new Purchase(order, products).getData()).push();

            store.push({
                expiry: add(new Date(), { days: 1 }).getTime(),
                id: order.id
            });
        }

        LocalStorageService.setItem(LOCAL_STORAGE_KEY.PURCHASE_STORE, JSON.stringify(store));
    }
}

export default new GTMService();

class Purchase {
    constructor(order, products) {
        this.order = order;
        this.products = products;
    }

    getData() {
        return {
            purchase: {
                actionField: this.order.getData(),
                products: this.products.map((product) => product.getData())
            }
        };
    }
}

export class Order {
    affiliation = AFFILIATION.LK3;

    constructor(id, revenue, tax, shipping, coupon) {
        this.id = id;
        this.revenue = revenue;
        this.tax = tax;
        this.shipping = shipping;
        this.coupon = coupon;
    }

    getData() {
        const data = {
            affiliation: this.affiliation,
            coupon: this.coupon || '',
            id: this.id,
            revenue: this.revenue?.toFixed(2),
            tax: this.tax || ''
        };
        if (this.shipping) {
            data['shipping'] = this.shipping;
        }
        return data;
    }
}

export class Product {
    constructor(article, name, brand, category, price, quantity, coupon) {
        this.article = article;
        this.name = name;
        this.brand = brand;
        this.category = category;
        this.price = parseInt(price);
        this.quantity = quantity;
        this.coupon = coupon;
    }

    getData() {
        return {
            brand: this.brand,
            category: this.category,
            coupon: this.coupon || '',
            article: this.article,
            name: this.name,
            price: this.price?.toFixed(2),
            quantity: this.quantity
        };
    }
}

export class DataLayer {
    event = EVENT.ADD_ECOMMERCE;

    constructor(funnel, step, param, page, data, eNI = '1') {
        this.funnel = funnel;
        this.step = step;
        this.param = param;
        this.page = page;
        this.data = data;
        this.eNI = eNI;
    }

    push() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            eNI: this.eNI,
            ecommerce: this.data,
            ecommerce_funnel: this.funnel,
            ecommerce_param: this.param,
            ecommerce_step: this.step,
            event: this.event,
            pageType: this.page
        });
    }
}

export function createSetProducts(product, products) {
    const setProducts = [];
    if (product) {
        setProducts.push(new Product(product.article, product.name, null, null, product.price || product.cost, 1, null).getData());
    }
    if (products) {
        products.filter((item) => {
            if ((product && item.classifierData && item.classifierData?.service) || !product) {
                setProducts.push(new Product(item.article, item.name, null, null, item.price || item.cost, 1, null).getData());
            }
        });
    }
    return setProducts;
}

export function removeEmptyField(obj) {
    return Object.keys(obj)
        .filter((k) => obj[k] !== null && obj[k] !== '')
        .reduce((newObj, k) => (typeof obj[k] === 'object' ? { ...newObj, [k]: removeEmptyField(obj[k]) } : { ...newObj, [k]: obj[k] }), {});
}
