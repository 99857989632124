import { useAppSelector } from 'app/Hooks';
import messages from 'page/cart/accepted/CartAcceptedPageMessages';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { externalOfficeSelector } from 'redux/cart/selectors';

import officeNameRounded from '../assets/office-name-rounded.svg';
import styles from './OfficeBlock.module.css';

const OfficeBlock = () => {
    const office = useAppSelector(externalOfficeSelector);
    const metro = office?.metroStations[0];
    const metroColor = metro?.metroLine?.color;
    const { formatMessage } = useIntl();

    return (
        <>
            {office && (
                <div>
                    <h2 className={styles.officeBlockTitle}>{formatMessage(messages.office)}</h2>
                    <div className={styles.container}>
                        <div className={styles.officePhoto}>
                            <img src={officeNameRounded} alt='' />
                        </div>
                        <div className={styles.officeInfo}>
                            <div className={styles.officeName}>
                                <div>{office.name}</div>
                            </div>

                            <div>{office.address}</div>
                            {metro && (
                                <div className={styles.metroContainer}>
                                    <div className={styles.metroInfo} style={{ color: metroColor || 'inherit' }}>
                                        <div className={styles.metroIcon}></div>
                                    </div>
                                    <span>{metro.name}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default OfficeBlock;
