import { createRequestTypes } from 'redux/helpers';

export const CREATE = createRequestTypes('ORDERS/CREATE');
export const CREATE_REORDER = createRequestTypes('ORDERS/CREATE_REORDER');
export const FETCH_ACCEPTED_INFO = createRequestTypes('ORDERS/FETCH_ACCEPTED_INFO');
export const PREORDER_PAY = createRequestTypes('ORDERS/PREORDER_PAY');
export const EXTERNAL_PREORDER_PAY = createRequestTypes('ORDERS/EXTERNAL_PAY');
export const CREATE_CHECKUP = createRequestTypes('ORDERS/CREATE_CHECKUP');
export const CANCEL = createRequestTypes('ORDERS/CANCEL');
export const ORDERS_ALL = createRequestTypes('ORDERS/ALL');
export const CLEAR_ORDERS_ALL = createRequestTypes('ORDERS/CLEAR_ALL');
export const CLEAR_DATA_STATE = createRequestTypes('ORDERS/CLEAR_DATA_STATE');
export const CLEAR_ACCEPTED_INFO = createRequestTypes('ORDERS/CLEAR_ACCEPTED_INFO');

export const clearDataState = () => CLEAR_DATA_STATE.base();
