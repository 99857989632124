//@ts-nocheck
import {
    ADD_PRODUCT,
    CALCULATE_CHECKUPS,
    CALCULATE_EXTERNAL_PREORDER,
    CALCULATE_HOME_VISIT,
    CALCULATE_OFFICE,
    CALCULATE_PAYMENT_PREORDER,
    CALCULATE_REORDER,
    CHECK_PRODUCTS_AVAILABLE,
    CHECK_REORDER_AVAILABILITY,
    CLEAR,
    CLEAR_ACTION_CART,
    CLEAR_APPOINTMENT,
    CLEAR_CHECKUPS_DATA,
    CLEAR_HOME_VISIT_DATA,
    CLEAR_OFFICE_DATA,
    CLEAR_ONLINE_REGISTRATION_CART_DATA,
    CLEAR_RECEIVED_CART,
    DELETE_INVALID_PRODUCTS,
    DELETE_PRODUCT,
    FETCH,
    FETCH_CART_BY_CODE,
    FETCH_PAYMENT_STATUS,
    MERGE_CART,
    RECEIVED_CART,
    REPEAT_ORDER,
    REPLACE_CART,
    RESET_CART_STATE,
    RESTORE_PRODUCT,
    SELECT_PATIENT,
    SELECT_PAYMENT_TYPE,
    SET_APPOINTMENT,
    SET_CART_ORDER_TYPE,
    SET_IS_EXTERNAL_PREORDER,
    SET_PAYMENT_PREORDER_ID,
    SET_PAYMENT_STATUS,
    SET_SALE_ACTION,
    SHARE_CART,
    SHOW_BIOMATERIAL_MODAL,
    TOGGLE_ONLINE_REGISTRATION,
    UPDATE_CHECKUP_DELIVERY_VALUE,
    UPDATE_DELIVERY_DATA,
    UPDATE_HOME_VISIT_DATA,
    UPDATE_OFFICE_TYPE,
    UPDATE_ONLINE_REGISTRATION_DATA
} from 'redux/cart/actions';
import { CART_ORDER_TYPE, PAYMENT_STATUS } from 'redux/cart/constants';
import { basicReducer } from 'redux/helpers';

const initialState = {
    actionCart: null,
    appointment: undefined,
    biomaterialModal: {
        productsIds: [],
        show: false
    },
    calculateCheckups: {},
    calculateHomeVisit: {},
    calculateOffice: {},
    calculatePaymentExternalPreorder: {},
    calculatePaymentPreorder: {},
    calculateReorder: {},
    cartByCode: {
        cityId: null,
        officeId: null,
        prevProducts: [],
        products: [],
        type: null
    },
    cartNumber: null,
    cartOrderType: null,
    checkReorderAvailability: {},
    checkupDelivery: {
        email: '',
        errors: {
            email: false,
            sms: false
        },
        sendMethod: 'EMAIL',
        sms: ''
    },
    checkupsData: {},
    data: {},
    deliveryData: {},
    externalPreorderError: false,
    fetch: {},
    fetchCartByCode: {},
    homeVisitData: {},
    officeData: {},
    officeProductsAvailable: [],
    officeType: undefined,
    onlineRegistrationData: {},
    onlineRegistrationEnabled: false,
    paymentExternalPreorderData: {},
    paymentPreorderData: {},
    paymentPreorderId: null,
    paymentStatus: {
        message: null,
        success: false,
        transactionStatus: PAYMENT_STATUS.AWAITING
    },
    receivedCart: false,
    reorderData: {},
    repeatOrder: {
        prevProducts: [],
        products: []
    },
    selectedPatientId: undefined,
    selectedPaymentType: undefined
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CART_ORDER_TYPE.BASE: {
            return {
                ...state,
                cartOrderType: action.meta
            };
        }
        case UPDATE_CHECKUP_DELIVERY_VALUE.BASE: {
            return {
                ...state,
                checkupDelivery: action.meta.data
            };
        }
        case FETCH.STARTED:
        case FETCH.SUCCEEDED:
        case FETCH.ERRORED: {
            let data = state.data;
            if (action.type === FETCH.SUCCEEDED) {
                data = action.payload.data;
                if (data.products) {
                    data.products.filter((item, i) => {
                        data.products[i].article = item.article.toString();
                    });
                }
            }
            return {
                ...state,
                data,
                fetchAll: basicReducer(state.fetch, action)
            };
        }

        case CALCULATE_OFFICE.STARTED:
        case CALCULATE_OFFICE.SUCCEEDED:
        case CALCULATE_OFFICE.ERRORED: {
            let officeData = state.officeData;
            if (action.type === CALCULATE_OFFICE.SUCCEEDED) {
                officeData = {
                    ...action.payload.data,
                    officeId: action.meta.officeId
                };
            }
            return {
                ...state,
                calculateOffice: basicReducer(state.calculateOffice, action),
                officeData
            };
        }
        case CALCULATE_EXTERNAL_PREORDER.STARTED:
        case CALCULATE_EXTERNAL_PREORDER.ERRORED:
        case CALCULATE_EXTERNAL_PREORDER.SUCCEEDED: {
            let paymentExternalPreorderData = state.paymentExternalPreorderData;
            if (action.type === CALCULATE_EXTERNAL_PREORDER.SUCCEEDED) {
                paymentExternalPreorderData = {
                    ...action.payload.data
                };
            }
            if (action.type === CALCULATE_EXTERNAL_PREORDER.ERRORED) {
                if (action.payload.status) {
                    return {
                        ...state,
                        calculatePaymentExternalPreorder: basicReducer(state.calculatePaymentExternalPreorder, action),
                        externalPreorderError: true,
                        paymentExternalPreorderData
                    };
                }
            }
            return {
                ...state,
                calculatePaymentExternalPreorder: basicReducer(state.calculatePaymentExternalPreorder, action),
                paymentExternalPreorderData
            };
        }

        case CALCULATE_PAYMENT_PREORDER.STARTED:
        case CALCULATE_PAYMENT_PREORDER.SUCCEEDED:
        case CALCULATE_PAYMENT_PREORDER.ERRORED: {
            let paymentPreorderData = state.paymentPreorderData;
            if (action.type === CALCULATE_PAYMENT_PREORDER.SUCCEEDED) {
                paymentPreorderData = {
                    ...action.payload.data
                };
            }
            return {
                ...state,
                calculatePaymentPreorder: basicReducer(state.calculatePaymentPreorder, action),
                paymentPreorderData
            };
        }

        case CALCULATE_HOME_VISIT.STARTED:
        case CALCULATE_HOME_VISIT.SUCCEEDED:
        case CALCULATE_HOME_VISIT.ERRORED: {
            let homeVisitData = state.homeVisitData;
            if (action.type === CALCULATE_HOME_VISIT.SUCCEEDED) {
                homeVisitData = {
                    ...homeVisitData,
                    ...action.payload.data
                };
            }
            return {
                ...state,
                calculateHomeVisit: basicReducer(state.calculateHomeVisit, action),
                homeVisitData
            };
        }

        case CALCULATE_CHECKUPS.STARTED:
        case CALCULATE_CHECKUPS.SUCCEEDED:
        case CALCULATE_CHECKUPS.ERRORED: {
            let checkupsData = state.checkupsData;
            if (action.type === CALCULATE_CHECKUPS.SUCCEEDED) {
                checkupsData = {
                    ...checkupsData,
                    ...action.payload.data
                };
            }
            return {
                ...state,
                calculateCheckups: basicReducer(state.calculateCheckups, action),
                checkupsData
            };
        }

        case CHECK_REORDER_AVAILABILITY.STARTED:
        case CHECK_REORDER_AVAILABILITY.SUCCEEDED:
        case CHECK_REORDER_AVAILABILITY.ERRORED: {
            let reorderData = state.reorderData;
            const available = action?.payload?.data;
            if (action.type === CHECK_REORDER_AVAILABILITY.SUCCEEDED) {
                reorderData = {
                    ...reorderData,
                    ...available
                };
            }
            return {
                ...state,
                checkReorderAvailability: basicReducer(state.checkReorderAvailability, action),
                reorderData
            };
        }

        case SET_PAYMENT_PREORDER_ID.BASE: {
            return {
                ...state,
                paymentPreorderId: action.meta
            };
        }

        case CALCULATE_REORDER.STARTED:
        case CALCULATE_REORDER.SUCCEEDED:
        case CALCULATE_REORDER.ERRORED: {
            let reorderData = state.reorderData;
            if (action.type === CALCULATE_REORDER.SUCCEEDED) {
                reorderData = {
                    ...reorderData,
                    ...action.payload.data
                };
            }
            return {
                ...state,
                calculateReorder: basicReducer(state.calculateReorder, action),
                reorderData
            };
        }

        case ADD_PRODUCT.SUCCEEDED: {
            const { article, price, rproduct, recommendationProduct, recommendationProfile, rprofile } = action.meta;
            let products = state.data.products || [];
            if (recommendationProduct && rproduct) {
                products = products.filter((product) => product.article !== rproduct.product.article);
                products.push({ ...rproduct.product, price: rproduct.cost });
            } else if (recommendationProfile && rprofile) {
                products = products.filter((product) => product.article !== rprofile.profile.article);
                products.push({ ...rprofile.profile, price: rprofile.cost });
            } else {
                products.push({ article, price });
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    products
                }
            };
        }

        case DELETE_PRODUCT.SUCCEEDED: {
            const { article, force } = action.meta;
            let products = state.data.products || [];
            if (force) {
                products = products.filter((product) => product.article !== article);
            } else {
                products.filter((product) => product.article === article).forEach((product) => (product.deleted = true));
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    products
                }
            };
        }

        case RESTORE_PRODUCT.BASE: {
            const { article } = action.meta;
            const products = state.data.products || [];
            products.filter((product) => product.article === article).forEach((product) => (product.deleted = false));
            return {
                ...state,
                data: {
                    ...state.data,
                    products
                }
            };
        }

        case DELETE_INVALID_PRODUCTS.SUCCEEDED: {
            const { articles } = action.meta;
            const products = (state.data.products || []).filter((product) => !articles.includes(product.article));
            return {
                ...state,
                data: {
                    ...state.data,
                    products
                }
            };
        }

        case UPDATE_OFFICE_TYPE.BASE: {
            return {
                ...state,
                officeType: action.meta.type
            };
        }

        case UPDATE_HOME_VISIT_DATA.BASE: {
            const calculateHomeVisit = state.calculateHomeVisit;
            return {
                ...state,
                homeVisitData: {
                    ...state.homeVisitData,
                    ...(calculateHomeVisit.response || {}),
                    ...action.meta.data
                }
            };
        }

        case UPDATE_ONLINE_REGISTRATION_DATA.BASE: {
            return {
                ...state,
                onlineRegistrationData: action.meta.data
            };
        }

        case CLEAR_ONLINE_REGISTRATION_CART_DATA.BASE: {
            return {
                ...state,
                onlineRegistrationData: {}
            };
        }

        case UPDATE_DELIVERY_DATA.BASE: {
            return {
                ...state,
                deliveryData: action.meta.data
            };
        }

        case SELECT_PATIENT.BASE: {
            return {
                ...state,
                selectedPatientId: action.meta.id
            };
        }

        case SELECT_PAYMENT_TYPE.BASE: {
            return {
                ...state,
                selectedPaymentType: action.meta.type
            };
        }

        case SHOW_BIOMATERIAL_MODAL.BASE:
            const { show, productsIds } = action.meta;
            return {
                ...state,
                biomaterialModal: {
                    ...state.biomaterialModal,
                    productsIds: productsIds || [],
                    show
                }
            };

        case TOGGLE_ONLINE_REGISTRATION.BASE:
            return {
                ...state,
                onlineRegistrationEnabled: !state.onlineRegistrationEnabled
            };

        case SET_APPOINTMENT.BASE: {
            return {
                ...state,
                appointment: action.meta
            };
        }

        case CLEAR_APPOINTMENT.BASE: {
            return {
                ...state,
                appointment: undefined
            };
        }

        case CLEAR_OFFICE_DATA.BASE: {
            return {
                ...state,
                calculateOffice: {},
                officeData: {},
                officeType: null
            };
        }

        case CLEAR_HOME_VISIT_DATA.BASE: {
            return {
                ...state,
                calculateHomeVisit: {},
                homeVisitData: {},
                officeType: null
            };
        }

        case CLEAR_CHECKUPS_DATA.BASE: {
            return {
                ...state,
                checkupsData: {}
            };
        }

        case CLEAR.BASE:
        case RESET_CART_STATE.BASE: {
            return initialState;
        }

        case SHARE_CART.STARTED:
        case SHARE_CART.SUCCEEDED:
        case SHARE_CART.ERRORED: {
            let cartNumber;
            if (action.type === SHARE_CART.SUCCEEDED) {
                cartNumber = action.payload.data.code;
            }
            return {
                ...state,
                cartNumber,
                fetch: basicReducer(state.fetch, action)
            };
        }

        case FETCH_CART_BY_CODE.STARTED:
        case FETCH_CART_BY_CODE.SUCCEEDED:
        case FETCH_CART_BY_CODE.ERRORED: {
            const data = action?.payload?.data || {};

            return {
                ...state,
                cartByCode: {
                    ...state.cartByCode,
                    ...data,
                    prevProducts: [...action.meta?.prevProducts],
                    products: data.products
                },
                fetchCartByCode: basicReducer(state.fetchCartByCode, action)
            };
        }

        case CLEAR_ACTION_CART.BASE: {
            return {
                ...state,
                actionCart: null,
                repeatOrder: null
            };
        }

        case SET_SALE_ACTION.BASE: {
            const cartOrderType = state.cartOrderType;
            let calculationDataName = null;
            if (cartOrderType === CART_ORDER_TYPE.ANALYZES) {
                calculationDataName = 'officeData';
            } else if (cartOrderType === CART_ORDER_TYPE.REORDER) {
                calculationDataName = 'reorderData';
            } else if (cartOrderType === CART_ORDER_TYPE.PAYMENT_PREORDER) {
                calculationDataName = 'paymentPreorderData';
            }

            const calculationData = {
                [calculationDataName]: {
                    ...state[calculationDataName],
                    saleAction: {
                        ...(state[calculationDataName].saleAction || {}),
                        ...action.meta.data
                    }
                }
            };

            return {
                ...state,
                ...calculationData
            };
        }

        case REPEAT_ORDER.STARTED:
        case REPEAT_ORDER.SUCCEEDED:
        case REPEAT_ORDER.ERRORED: {
            let products;
            if (action.type === REPEAT_ORDER.SUCCEEDED) {
                products = action.payload.data.products;
            }
            return {
                ...state,
                repeatOrder: {
                    ...state.repeatOrder,
                    prevProducts: [...action.payload.data?.prevProducts],
                    products
                }
            };
        }

        case MERGE_CART.STARTED:
        case MERGE_CART.SUCCEEDED:
        case MERGE_CART.ERRORED: {
            let data = {};
            if (action.type === MERGE_CART.SUCCEEDED) {
                data = action.payload.data;
            }
            return {
                ...state,
                ...data,
                checkupsData: {},
                fetch: basicReducer(state.fetch, action)
            };
        }

        case REPLACE_CART.STARTED:
        case REPLACE_CART.SUCCEEDED:
        case REPLACE_CART.ERRORED: {
            let data = {};
            if (action.type === REPLACE_CART.SUCCEEDED) {
                data = action.payload.data;
            }
            return {
                ...state,
                ...data,
                checkupsData: {},
                fetch: basicReducer(state.fetch, action)
            };
        }

        case CHECK_PRODUCTS_AVAILABLE.STARTED:
        case CHECK_PRODUCTS_AVAILABLE.SUCCEEDED:
        case CHECK_PRODUCTS_AVAILABLE.ERRORED: {
            let data = [];
            if (action.type === CHECK_PRODUCTS_AVAILABLE.SUCCEEDED) {
                data = action.payload.data;
            }
            return {
                ...state,
                officeProductsAvailable: [...data]
            };
        }

        case FETCH_PAYMENT_STATUS.AWAITING:
        case FETCH_PAYMENT_STATUS.SUCCEEDED:
        case FETCH_PAYMENT_STATUS.ERRORED: {
            return {
                ...state,
                paymentStatus: action.payload
            };
        }
        case SET_PAYMENT_STATUS.BASE: {
            return {
                ...state,
                paymentStatus: action.meta
            };
        }
        case RECEIVED_CART.BASE: {
            return {
                ...state,
                receivedCart: true
            };
        }
        case CLEAR_RECEIVED_CART.BASE: {
            return {
                ...state,
                receivedCart: false
            };
        }
        default:
            return state;
    }
}
