//@ts-nocheck
import Cost from 'containers/const/Cost';
import styles from 'page/cart/confirmation/orderBlock/orderList/OrderList.module.css';
import messages from 'page/cart/confirmation/orderBlock/orderList/OrderListMessages';
import React from 'react';
import { injectIntl } from 'react-intl';

const OrderList = ({ currency, intl, cartOrderType, services, cost, loading }) => {
    const renderOrderItem = (title, amount, key = null) => (
        <div className={styles.orderListItem} key={key}>
            <div className={styles.orderListItemTitle}>{title}</div>
            {!loading && (
                <div className={styles.orderListItemPrice}>
                    <Cost defaultValue='-' amount={amount} currency={currency} />
                </div>
            )}
        </div>
    );

    const itemTitle = intl.formatMessage(!cartOrderType.checkups ? messages.itemTitle : messages.checkupTitle);

    return (
        <div className={styles.orderListItems}>
            {renderOrderItem(itemTitle, cost.productsCost)}
            {services?.map((service) => renderOrderItem(`${service.name}:`, service.price, service.id))}
        </div>
    );
};

export default injectIntl(OrderList);
