//@ts-nocheck
import cn from 'classnames';
import FormField from 'components/form/field/FormField';
import cvvIcon from 'components/icon/svg/cvvIcon.svg';
import Input from 'components/input/Input';
import commonMessages from 'messages/CommonMessages';
import { cardFormConfig, INPUT_ERRORS_CODES, INPUTS_LIST } from 'page/cart/confirmation/cardForm/cardForm.config';
import messages from 'page/cart/confirmation/cardForm/cardForm.messages';
import { cardValidation } from 'page/cart/confirmation/cardForm/cardForm.validations';
import Actions from 'page/cart/confirmation/cardForm/components/ActionsBlock';
import InputBlock from 'page/cart/confirmation/cardForm/components/InputBlock';
import { PaymentSystems } from 'page/cart/confirmation/cardForm/components/PaymentSystems';
import React, { useCallback, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { validateEmail } from 'utils/validators';

import styles from './CardForm.module.css';

const CardForm = ({ intl, cart, confirmable, cartOrderType, onConfirmation }) => {
    const [formData, setFormData] = useState({
        cardNumber: '',
        cvv: '',
        expDate: ''
    });
    const [inputErrors, setInputErrors] = useState({
        cardNumber: '',
        cvv: '',
        expDate: ''
    });
    const [cpErrors, setCpErrors] = useState({});
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});

    const inputs = {
        cardNumber: {
            focused: false,
            ref: useRef(null),
            touched: false
        },
        cvv: {
            focused: false,
            ref: useRef(null),
            touched: false
        },
        expDate: {
            focused: false,
            ref: useRef(null),
            touched: false
        }
    };

    const handleFormEdit = useCallback(
        (key, value) => {
            if (inputErrors[key] || cpErrors[key]) {
                setInputErrors((prevErrors) => ({
                    ...prevErrors,
                    [key]: ''
                }));
                setCpErrors((prevErrors) => ({
                    ...prevErrors,
                    [key]: ''
                }));
            }

            const valueLength = value.replaceAll(' ', '').replace('/', '').length;

            switch (key) {
                case INPUTS_LIST.CARD_NUMBER:
                    if (valueLength === 16 && !inputs[key].ref.touched) {
                        inputs.expDate.ref.current.focus();
                    }
                    break;
                case INPUTS_LIST.EXP_DATE:
                    if (!inputErrors.expDate && valueLength === 4) {
                        inputs.cvv.ref.current.focus();
                    }
                    break;
                default:
                    break;
            }

            setFormData((prevData) => ({
                ...prevData,
                [key]: value
            }));
        },
        [inputErrors, cpErrors]
    );

    const onBlurValidation = useCallback(
        (key, event) => {
            const valueWithoutSpace = event.target.value.replaceAll(' ', '');
            const validator = cardValidation(valueWithoutSpace);

            try {
                switch (key) {
                    case INPUTS_LIST.CARD_NUMBER:
                        validator.number.empty();
                        validator.number.length();
                        break;
                    case INPUTS_LIST.EXP_DATE:
                        validator.date.empty();
                        const yearWithZero = validator.date.addZero();
                        if (yearWithZero) {
                            setFormData((prevData) => ({
                                ...prevData,
                                [key]: yearWithZero
                            }));
                        }
                        cardValidation(yearWithZero || valueWithoutSpace).date.min();
                        cardValidation(yearWithZero || valueWithoutSpace).date.betweenMonth();
                        break;
                    case INPUTS_LIST.CVV:
                        validator.cvv.empty();
                        validator.cvv.min();
                        break;
                    default:
                        break;
                }
            } catch (e) {
                setInputErrors((prevErrors) => ({
                    ...prevErrors,
                    [key]: e.message
                }));
            }
        },
        [inputs]
    );

    const getTranslatedError = useCallback(
        (key) => {
            const errors = cpErrors.cpErrors || {};
            if (!errors[key] && !inputErrors[key]) {
                return;
            }

            if (!inputErrors[key] && !Object.keys(INPUT_ERRORS_CODES).includes(errors[key])) {
                return intl.formatMessage(messages['Unknown_error']);
            }

            return intl.formatMessage(messages[errors[key] || inputErrors[key]]);
        },
        [inputErrors, cpErrors]
    );
    return (
        <>
            {(!cart.hasAppliedCertificates || (cart.hasAppliedCertificates && !cart.isPrepaid)) && (
                <>
                    <h2 className={styles.title}>{intl.formatMessage(messages.title)}</h2>
                    <form className={styles.form}>
                        {Object.values(INPUTS_LIST).map((key) => (
                            <InputBlock key={key} className={styles[key]} title={intl.formatMessage(messages[key])} error={getTranslatedError(key)}>
                                <InputBlock.Input
                                    maskOptions={{ mask: cardFormConfig[key].mask }}
                                    onInput={(e) => handleFormEdit(key, e.target.value)}
                                    onChange={(e) => handleFormEdit(key, e.target.value)}
                                    value={formData[key]}
                                    input={inputs[key]}
                                    onBlur={(e) => onBlurValidation(key, e)}
                                    {...cardFormConfig[key]}
                                />
                                {key === INPUTS_LIST.CVV && <InputBlock.Icon src={cvvIcon} alt={'cvv-icon'} />}
                            </InputBlock>
                        ))}
                    </form>
                    {confirmable.hasEmail && (
                        <FormField className={cn(styles.email, { [styles.error]: errors.email })} label={intl.formatMessage(commonMessages.emailForCheck)}>
                            <Input
                                name='email'
                                placeholder={intl.formatMessage(commonMessages.requiredField)}
                                value={email}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    setEmail(value);
                                    setErrors((prevState) => ({ ...prevState, email: !validateEmail(value) }));
                                }}
                                error={errors.email}
                                className={cn(styles.inputEmail, { [styles.error]: errors.email })}
                            />
                            {errors.email && <span className={styles.textError}>{intl.formatMessage(commonMessages.enterEmail)}</span>}
                        </FormField>
                    )}
                    <PaymentSystems intl={intl} />
                </>
            )}
            <Actions
                cartOrderType={cartOrderType}
                email={email}
                emailError={errors}
                intl={intl}
                setState={() => setFormData}
                setErrors={setCpErrors}
                formData={formData}
                inputErrors={inputErrors}
                onConfirmation={onConfirmation}
            />
        </>
    );
};

export default injectIntl(CardForm);
