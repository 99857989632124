//@ts-nocheck
import classNames from 'classnames/bind';
import Cost from 'containers/const/Cost';
import commonMessages from 'messages/CommonMessages';
import styles from 'page/cart/confirmation/orderBlock/productList/ProductList.module.css';
import messages from 'page/cart/confirmation/orderBlock/productList/ProductListMessages';
import * as React from 'react';
import { injectIntl } from 'react-intl';

const cx = classNames.bind(styles);

class ProductList extends React.PureComponent {
    state = {
        showOrderContents: false
    };

    toggleOrderContents = () => {
        this.setState((prevState) => ({ showOrderContents: !prevState.showOrderContents }));
    };

    renderOrderToggle = () => {
        const { intl, cartOrderType } = this.props;
        const { showOrderContents } = this.state;

        const message = cartOrderType.reorder
            ? showOrderContents
                ? messages.reorderContentsLinkHide
                : messages.reorderContentsLinkShow
            : showOrderContents
            ? messages.orderContentsLinkHide
            : messages.orderContentsLinkShow;

        return (
            <div onClick={this.toggleOrderContents} className={styles.orderContentsToggle}>
                <span className={showOrderContents ? styles.orderContentsLinkHide : styles.orderContentsLinkShow}>{intl.formatMessage(message)}</span>
                {showOrderContents && <span className={styles.orderContentsIcon}>×</span>}
            </div>
        );
    };

    renderProduct = (product, currency, intl) => (
        <div className={styles.orderContentsItem} key={product.article}>
            <div className={styles.orderContentsItemTitle}>{product.name}</div>
            {product.price || product.cost ? (
                <div className={styles.orderContentsItemPrice}>
                    <Cost amount={product.priceWithDiscount || product.cost} currency={currency} />
                    <Cost
                        amount={product.price || product.cost}
                        currency={currency}
                        className={styles.priceWithDiscount}
                        show={(product.price || product.cost) > product.priceWithDiscount}
                    />
                    {product.quantity > 1 && (
                        <div className={styles.onePrice}>
                            <Cost amount={product.price || product.costPerItem} currency={currency} /> × {product.quantity}{' '}
                            {intl.formatMessage(commonMessages.quantity)}
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );

    render() {
        const { cart, intl, cartOrderType, currency, checkups } = this.props;
        const { showOrderContents } = this.state;

        const classNameOrderContents = cx({
            orderContentsHidden: !showOrderContents,
            orderContentsShowing: showOrderContents
        });

        const analyzes = cart.products.filter((product) => !product.deleted);
        const checkupProducts = checkups?.filter((checkup) => !checkup.deleted);
        const orders = cartOrderType.analyzes || cartOrderType.reorder || cartOrderType.paymentPreorder ? analyzes : checkupProducts;

        if (orders.length === 0) return null;

        return (
            <React.Fragment>
                <div>
                    {this.renderOrderToggle()}
                    <div className={classNameOrderContents}>{orders.map((product) => this.renderProduct(product, currency, intl))}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(ProductList);
