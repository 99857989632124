//@ts-nocheck
import cn from 'classnames';
import Button from 'components/button/Button';
import FormField from 'components/form/field/FormField';
import Input from 'components/input/Input';
import commonMessages from 'messages/CommonMessages';
import messages from 'page/cart/confirmation/cardForm/cardForm.messages';
import React, { Component, Fragment } from 'react';
import FormHelper from 'utils/FormHelper';
import { isAdult } from 'utils/patientUtils';
import { validateEmail, validateNotBlank, validatePhoneNumber } from 'utils/validators';

import styles from './PaymentButton.module.css';

export class PaymentButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errors: {},
            paymentEmail: this.props?.user?.email,
            phone: this.props?.user?.phone
        };
        this.onConfirmation = this.onConfirmation.bind(this);

        const validators = {
            email: (email) => validateNotBlank(email) && validateEmail(email),
            paymentEmail: (email) => validateNotBlank(email) && validateEmail(email),
            phone: (phone) => validateNotBlank(phone) && validatePhoneNumber(phone, ['RU'])
        };
        this.formHelper = new FormHelper(this, validators);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const user = this.props.user;
        if (prevProps.user !== user) {
            this.setState({
                paymentEmail: user?.email,
                phone: user?.phone
            });
        }
    }

    onConfirmation() {
        this.props.onConfirmation({ email: this.state.email, paymentEmail: this.state.paymentEmail, phone: this.state.phone });
    }

    isValid(fieldName) {
        return this.formHelper.isValid([fieldName]);
    }

    render() {
        const { intl, confirmable, onlineRegistrationEnabled, cartOrderType, mainPatient, authenticated, user, message = null } = this.props;
        const checkDisabled =
            // Условие 1: Confirmable не является валидным
            !confirmable.valid ||
            // Условие 2: Не внешний предзаказ, пациент несовершеннолетний, и включена онлайн-регистрация
            (!cartOrderType?.externalPreorder && !isAdult(mainPatient) && onlineRegistrationEnabled) ||
            // Условие 3: Не внешний предзаказ, требуется email, но email невалиден
            (!cartOrderType?.externalPreorder && confirmable.hasEmail && !this.isValid('email')) ||
            // Условие 4: Требуется номер телефона, но он невалиден
            (confirmable.hasPhone && !this.isValid('phone')) ||
            // Условие 5: Требуются данные для согласования, но номер телефона или email для оплаты невалидны
            (confirmable.hasApprovalData && (!this.isValid('phone') || !this.isValid('paymentEmail')));

        return (
            <>
                {confirmable.hasPhone && (
                    <FormField className={styles.approvalData} label={intl.formatMessage(commonMessages.paymentPhoneNotice)}>
                        <FormField label={intl.formatMessage(commonMessages.phone)}>
                            <Input
                                name='phone'
                                value={this.state.phone}
                                defaultValue={user?.phone}
                                error={this.state.errors.phone}
                                valid={this.isValid('phone')}
                                placeholder={intl.formatMessage(commonMessages.requiredField)}
                                onChange={(event) => this.formHelper.handleValueChange(event)}
                            />
                        </FormField>
                    </FormField>
                )}
                {confirmable.hasApprovalData && (
                    <FormField className={styles.approvalData} label={intl.formatMessage(commonMessages.approvalData)}>
                        <FormField label={intl.formatMessage(commonMessages.phone)}>
                            <Input
                                name='phone'
                                value={this.state.phone}
                                defaultValue={user?.phone}
                                error={this.state.errors.phone}
                                valid={this.isValid('phone')}
                                placeholder={intl.formatMessage(commonMessages.requiredField)}
                                onChange={(event) => this.formHelper.handleValueChange(event)}
                            />
                        </FormField>
                        <FormField label={intl.formatMessage(commonMessages.email)}>
                            <Input
                                name='paymentEmail'
                                value={this.state.paymentEmail}
                                defaultValue={user?.email}
                                error={this.state.errors.paymentEmail}
                                valid={this.isValid('paymentEmail')}
                                placeholder={intl.formatMessage(commonMessages.requiredField)}
                                onChange={(event) => this.formHelper.handleValueChange(event)}
                            />
                        </FormField>
                    </FormField>
                )}
                {confirmable.hasEmail && !cartOrderType.externalPreorder && authenticated && (
                    <FormField
                        className={cn(styles.email, { [styles.error]: this.state.errors.email })}
                        label={intl.formatMessage(commonMessages.emailForCheck)}
                    >
                        <Input
                            name='email'
                            placeholder={intl.formatMessage(commonMessages.requiredField)}
                            defaultValue={this.state.email}
                            onChange={(event) => {
                                this.formHelper.handleValueChange(event);
                            }}
                            error={this.state.errors.email}
                            className={cn(styles.inputEmail, { [styles.error]: this.state.errors.email })}
                        />
                        {this.state.errors.email && <span className={styles.textError}>{intl.formatMessage(commonMessages.enterEmail)}</span>}
                    </FormField>
                )}
                <Button disabled={checkDisabled} onClick={this.onConfirmation}>
                    {intl.formatMessage(message || messages.confirmButton)}
                </Button>
            </>
        );
    }
}
