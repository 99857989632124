import { createRequestTypes } from 'redux/helpers';

export const LOGIN = createRequestTypes('AUTH/LOGIN');
export const LOGIN_AUTHENTICATED = createRequestTypes('AUTH/LOGIN_AUTHENTICATED');
export const CLEAR_LOGIN_ERRORS = createRequestTypes('AUTH/CLEAR_LOGIN_ERRORS');
export const LOGIN_CONFIRM = createRequestTypes('AUTH/LOGIN_CONFIRM');
export const LOGIN_CONFIRM_AUTHENTICATED = createRequestTypes('AUTH/LOGIN_CONFIRM_AUTHENTICATED');
export const LOGIN_PASSWORD = createRequestTypes('AUTH/LOGIN_PASSWORD');
export const AUTHORIZATION = createRequestTypes('AUTH/AUTHORIZATION');
export const REGISTER = createRequestTypes('AUTH/REGISTER');
export const RECOVERY = createRequestTypes('AUTH/RECOVERY');
export const RECOVERY_CONFIRM_BY_CODE = createRequestTypes('AUTH/RECOVERY_CONFIRM_BY_CODE');
export const RECOVERY_CONFIRM_BY_INZ = createRequestTypes('AUTH/RECOVERY_CONFIRM_BY_INZ');
export const RECOVERY_SET_PASSWORD = createRequestTypes('AUTH/RECOVERY_SET_PASSWORD');
export const RESET_PASSWORD = createRequestTypes('AUTH/RESET_PASSWORD');
export const REACTIVATION = createRequestTypes('AUTH/REACTIVATION');
export const REACTIVATION_CONFIRM = createRequestTypes('AUTH/REACTIVATION_CONFIRM');
export const RESET_AUTH_STATE = createRequestTypes('AUTH/RESET_AUTH_STATE');

export const CLEAR = createRequestTypes('AUTH/CLEAR');
export const LOGOUT = createRequestTypes('AUTH/LOGOUT');
export const AUTHENTICATE = createRequestTypes('AUTH/AUTHENTICATE');
export const DEAUTHENTICATE = createRequestTypes('AUTH/DEAUTHENTICATE');

export const CLEAR_LOGIN = createRequestTypes('AUTH/CLEAR_LOGIN');
export const CLEAR_REGISTRATION = createRequestTypes('AUTH/CLEAR_REGISTRATION');
export const CLEAR_CONFIRMATION = createRequestTypes('AUTH/CLEAR_CONFIRMATION');
export const CLEAR_RECOVERY = createRequestTypes('AUTH/CLEAR_RECOVERY');
export const CLEAR_REACTIVATION = createRequestTypes('AUTH/CLEAR_REACTIVATION');
export const SET_ACTION_LOGIN = createRequestTypes('AUTH/SET_ACTION_LOGIN');
export const SET_BACK_REF_URL = createRequestTypes('AUTH/SET_BACK_REF_URL');

export const login = (payload: { type?: 'LOGIN' | 'ADD'; territory: string; login: string; captcha: string | null }) => LOGIN.base(payload);
export const loginAuthenticated = (payload: { type?: 'UPDATE' | 'ADD'; territory: string; login: string }) => LOGIN_AUTHENTICATED.base(payload);
export const clearLoginErrors = () => CLEAR_LOGIN_ERRORS.base();
export const loginConfirm = (payload: { code: string; login: string }) => LOGIN_CONFIRM.base(payload);
export const loginConfirmAuthenticated = (payload: { code: string; login: string }) => LOGIN_CONFIRM_AUTHENTICATED.base(payload);
export const loginPassword = (payload: { password: string; territory: string; confirmedLoginToken: string }) => LOGIN_PASSWORD.base(payload);
export const authorization = (payload: { authorizationCode: string }) => AUTHORIZATION.base(payload);

export const register = (payload?: {
    confirmedLoginPhoneToken: string;
    confirmedLoginEmailToken: string;
    password: string;
    passwordRepeat: string;
    territory: string;
    subscribe: boolean;
    acceptUserAgreement: boolean;
    acceptPersonalAgreement?: boolean;
}) => REGISTER.base(payload);

export const recovery = (payload: { territory: string; confirmedLoginToken: string }) => RECOVERY.base(payload);

export const resetPassword = (payload: { confirmedLoginToken: string; password: string }) => RESET_PASSWORD.base(payload);

export const recoveryConfirmByCode = (payload: { confirmedLoginToken: string; code: string }) => RECOVERY_CONFIRM_BY_CODE.base(payload);

export const recoveryConfirmByINZ = (payload: { confirmedLoginToken: string; inz: string; birthDate: string; lastName: string }) =>
    RECOVERY_CONFIRM_BY_INZ.base(payload);

export const recoverySetPassword = (payload: { recoveryToken: string; password: string }) => RECOVERY_SET_PASSWORD.base(payload);

export const reactivation = (payload: { territory: string; confirmedLoginToken: string }) => REACTIVATION.base(payload);

export const reactivationConfirm = (payload: { confirmedLoginToken: string; code: string }) => REACTIVATION_CONFIRM.base(payload);

export const resetAuthState = () => RESET_AUTH_STATE.base();

export const logout = () => LOGOUT.base();

export const clear = () => CLEAR.base();

export const setBackRefUrl = (payload: { backRefUrl: null | string }) => SET_BACK_REF_URL.base(payload);
