import classNames from 'classnames/bind';
import Cost from 'containers/const/Cost';
import commonMessages from 'messages/CommonMessages';
import * as React from 'react';
import { FC, useState } from 'react';
import { injectIntl } from 'react-intl';

import styles from './ProductList.module.css';
import messages from './ProductListMessages';

const cx = classNames.bind(styles);

const ExternalProductList: FC<any> = (props) => {
    const { orderProducts, intl, cartOrderType, currency } = props;
    const [showOrderContents, setShowOrderContents] = useState(false);

    const classNameOrderContents = cx({
        orderContentsHidden: !showOrderContents,
        orderContentsShowing: showOrderContents
    });

    return (
        <>
            {orderProducts?.length > 0 && (
                <div>
                    <div>
                        {!showOrderContents && (
                            <div>
                                <span
                                    onClick={() => {
                                        setShowOrderContents(true);
                                    }}
                                    className={styles.orderContentsLinkShow}
                                >
                                    {intl.formatMessage(cartOrderType.reorder ? messages.reorderContentsLinkShow : messages.orderContentsLinkShow)}
                                </span>
                            </div>
                        )}
                        {showOrderContents && (
                            <div
                                onClick={() => {
                                    setShowOrderContents(false);
                                }}
                            >
                                <span className={styles.orderContentsLinkHide}>
                                    {intl.formatMessage(cartOrderType.reorder ? messages.reorderContentsLinkHide : messages.orderContentsLinkHide)}
                                </span>
                                <span className={styles.orderContentsIcon}>×</span>
                            </div>
                        )}
                    </div>
                    <div className={classNameOrderContents}>
                        {orderProducts.map((orderItem: any) => (
                            <div className={styles.orderContentsItem} key={orderItem.product.article}>
                                <div className={styles.orderContentsItemTitle}>{orderItem.product.name}</div>
                                <div className={styles.orderContentsItemPrice}>
                                    <Cost amount={orderItem.costWithDiscount || orderItem.cost} currency={currency} />
                                    <Cost
                                        currency={currency}
                                        amount={orderItem.cost}
                                        className={styles.priceWithDiscount}
                                        show={orderItem.cost > orderItem.costWithDiscount}
                                    />
                                    {orderItem.product?.quantity > 1 && (
                                        <div className={styles.onePrice}>
                                            <Cost amount={orderItem.price || orderItem.costPerItem} currency={currency} /> × {orderItem.product.quantity}{' '}
                                            {intl.formatMessage(commonMessages.quantity)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};
export default injectIntl(ExternalProductList);
