import { MessageDescriptor } from '@formatjs/intl/src/types';
import { Currency } from 'constants/currency';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core';
import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';

type TProps = {
    show?: boolean;
    amount: number;
    currency: string;
    className?: string;
    customFormatMessage?: {
        message: MessageDescriptor;
        values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>;
        opts?: IntlMessageFormatOptions;
    };
    defaultValue?: string;
};

// @ts-ignore
const Cost: FC<TProps> = ({ show = true, amount, currency, className, customFormatMessage, defaultValue }) => {
    const { formatMessage } = useIntl();
    const { formatNumber } = useIntl();

    const formatCost = (amount: number, currency: string) => {
        if (!currency || currency === Currency.RUR) {
            currency = Currency.RUB;
        }

        return formatNumber(amount, {
            currency,
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 0,
            style: 'currency'
        });
    };

    if (!show) {
        return null;
    }

    return defaultValue && !(amount >= 0) ? (
        defaultValue
    ) : (
        <span className={className}>
            {customFormatMessage
                ? formatMessage(customFormatMessage.message, {
                      ...customFormatMessage.values,
                      cost: formatCost(amount, currency)
                  })
                : formatCost(amount, currency)}
        </span>
    );
};

export default memo(Cost);
