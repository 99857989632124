import cn from 'classnames/bind';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
const classNames = cn.bind(styles);
import Input from 'components/input/Input';
import { ErrorResponse } from 'types/common';

import styles from './InputSplitted.module.css';

type TProps = {
    length: number;
    className?: string;
    error: ErrorResponse | boolean;
    done: (value: string) => void;
    onError?: () => void;
};

const InputSplitted: FC<TProps> = ({ length, done, onError, error }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [values, setValues] = useState<string[]>([]);
    const [inputError, setInputError] = useState<ErrorResponse | null | boolean>(null);

    useEffect(() => {
        containerRef.current?.querySelectorAll('input')[0].focus();
    }, []);

    useEffect(() => {
        setInputError(null);
        if (values?.filter((values) => values?.length > 0)?.length === length) {
            done(values.join(''));
        }
    }, [values]);

    useEffect(() => {
        setInputError(error);
        if (onError) {
            onError();
        }
    }, [error]);

    const moveFocus = (value: string, index: number) => {
        const inputs = containerRef.current?.querySelectorAll('input');
        if (!(value.length > 0) && index > 0) {
            if (inputs) {
                inputs[index - 1].focus();
            }
        }
        if (value.length > 0 && index < length - 1) {
            if (inputs) {
                inputs[index + 1].focus();
            }
        }
    };

    return (
        <div className={styles.container} ref={containerRef}>
            {Array.from(Array(length).keys()).map((_, index) => {
                return (
                    <Input
                        min={0}
                        key={index}
                        type='number'
                        pattern='[0-9]*'
                        inputMode={'numeric'}
                        value={values[index] || ''}
                        className={classNames({ error: inputError })}
                        onKeyDown={(event) => {
                            if (['e', 'E', '+', '-', '.', ','].includes(event.key)) {
                                event.preventDefault();
                                return;
                            }
                            if (!(values[index]?.length > 0) && event.key === 'Backspace') {
                                moveFocus(values[index] || '', index);
                            }
                        }}
                        onChange={(event) => {
                            const value = event.target.value;

                            if (value.length > 1 && value.length !== length && index === 0) {
                                event.preventDefault();
                                return false;
                            }

                            if (value.length > 1 && index > 0) {
                                event.preventDefault();
                                return false;
                            }

                            if (value.length === length && index === 0) {
                                const values = value
                                    .substring(0, length++)
                                    .split('')
                                    .map((value) => value);

                                setValues(values);
                                return true;
                            }

                            setValues((prevState) => {
                                const newState = [...prevState];
                                newState[index] = value;
                                return [...newState];
                            });

                            moveFocus(value, index);
                        }}
                    />
                );
            })}
        </div>
    );
};

export default memo(InputSplitted);
