//@ts-nocheck
import * as cartClient from 'client/CartClient';
import * as orderClient from 'client/OrderClient';
import * as reorderClient from 'client/ReorderClient';
import { getCartOfficeId, getCartOrderId } from 'redux/cart/sagas';
import {
    appointmentSelector,
    cartAnalyzesSelector,
    cartCheckupDeliverySelector,
    commonCalculationDataSelector,
    paymentTypeSelector,
    productsSelector,
    saleActionSelector,
    selectedPatientSelector
} from 'redux/cart/selectors';
import { currentCheckupCitySelector, currentTerritorySelector } from 'redux/cities/selectors';
import { request } from 'redux/helpers';
import { chosenOfficeSelector } from 'redux/offices/selectors';
import { CANCEL, CREATE, CREATE_CHECKUP, CREATE_REORDER, EXTERNAL_PREORDER_PAY, FETCH_ACCEPTED_INFO, ORDERS_ALL, PREORDER_PAY } from 'redux/orders/actions';
import { getOnlineRegistrationData } from 'redux/patient/selectors';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import GTMService, { Order, PAGE, PARAM, Product } from 'services/GTMService';
import { getLoyaltyOption, getOnlinePaymentData } from 'utils/orderUtils';

function* fetchOrders(action) {
    const { patientId } = action.meta;
    yield request(ORDERS_ALL, [orderClient.searchOrders, patientId], patientId);
}

function* create(action) {
    const cartOffice = yield select(chosenOfficeSelector);
    const cart = yield select(cartAnalyzesSelector);
    const saleAction = yield select(saleActionSelector);
    const appointment = yield select(appointmentSelector);
    const selectedPatient = yield select(selectedPatientSelector);
    const selectedPaymentType = yield select(paymentTypeSelector);
    const territory = yield select(currentTerritorySelector);
    const { officeId, orderProductsKeys, cardCryptogramPacket, phone, paymentEmail } = action.meta;

    const loyaltyOption = getLoyaltyOption(saleAction);
    const onlinePaymentData = getOnlinePaymentData({ cardCryptogramPacket, paymentEmail, phone, selectedPaymentType });

    // В случае онлайн-регистрации, при необходимости передать дополнительные данные о пациенте (паспорт, адрес, контакты для доставки)
    const onlineRegistrationEnabled = yield select((state) => state.cart.onlineRegistrationEnabled);
    const patientOnlineRegistration = yield select(getOnlineRegistrationData);
    const onlineRegistrationDataHolder = {};
    if (onlineRegistrationEnabled) {
        const onlineRegistrationBlockData = yield select((state) => state.cart.onlineRegistrationData);
        const deliveryData = yield select((state) => state.cart.deliveryData);
        const data = {
            ...onlineRegistrationBlockData,
            ...deliveryData
        };
        for (const [key, value] of Object.entries(data)) {
            const keyParts = key.split('.');
            if (keyParts.length === 1) {
                onlineRegistrationDataHolder[key] = value;
            }
            let currentObject = onlineRegistrationDataHolder;
            for (let i = 0; i < keyParts.length; i++) {
                const currentKey = keyParts[i];
                if (i === keyParts.length - 1) {
                    currentObject[currentKey] = typeof value === 'object' && !(value instanceof Date) ? value.value : value;
                } else {
                    currentObject[currentKey] = currentObject[currentKey] || {};
                    currentObject = currentObject[currentKey];
                }
            }
        }
    }

    // Если у пациента были заполнены данные паспорта, то не нужно их передавать
    if (patientOnlineRegistration?.hasPassport) {
        delete onlineRegistrationDataHolder?.onlineRegistrationData?.passport;
    }

    // Если у пациента был заполнен адрес регистрации, то не нужно его передавать
    if (patientOnlineRegistration?.hasAddress) {
        delete onlineRegistrationDataHolder?.onlineRegistrationData?.address;
    }

    // Подготовить данные для запроса
    const data = {
        ...action.meta,
        appointment,
        loyaltyOption,
        officeId: officeId || cartOffice.office.id,
        onlinePaymentData,
        onlineRegistration: onlineRegistrationEnabled,
        onlineRegistrationData: onlineRegistrationDataHolder.onlineRegistrationData,
        orderProductsKeys:
            orderProductsKeys ||
            cart.products
                .filter((product) => !product.deleted)
                .map((product) => {
                    return { biomaterialId: product.biomaterial?.id, productId: product.id };
                }),
        patientId: selectedPatient?.id,
        territory
    };

    yield request(CREATE, [orderClient.create, data], data);
}

function* createReorder(action) {
    const { cardCryptogramPacket, phone, paymentEmail, email } = action.meta;
    const officeId = yield getCartOfficeId();
    const orderId = yield getCartOrderId();
    const saleAction = yield select(saleActionSelector);
    const selectedPaymentType = yield select(paymentTypeSelector);
    const territory = yield select(currentTerritorySelector);
    const { analyzes } = yield select(productsSelector);
    const productIds = analyzes.valid.map((product) => product.id);

    const loyaltyOption = getLoyaltyOption(saleAction);
    const onlinePaymentData = getOnlinePaymentData({ cardCryptogramPacket, paymentEmail, phone, selectedPaymentType });

    const data = {
        ...action.meta,
        email,
        loyaltyOption,
        officeId,
        onlinePaymentData,
        orderId,
        productIds,
        territory
    };

    yield request(CREATE_REORDER, [reorderClient.createReorder, data], data);
}

function* fetchAcceptedInfo(action) {
    const data = action.meta;
    yield request(FETCH_ACCEPTED_INFO, [orderClient.fetchAcceptedInfo, data], data);
}

function* createCheckupOrder(action) {
    const selectedPaymentType = yield select(paymentTypeSelector);
    const { cardCryptogramPacket, phone, paymentEmail, email } = action.meta;
    const territory = yield select(currentTerritorySelector);
    const checkupDelivery = yield select(cartCheckupDeliverySelector);
    const { checkups } = yield select(productsSelector);
    const { id: cityId } = yield select(currentCheckupCitySelector);

    const data = {
        checkups: checkups.valid.map((checkup) => {
            return {
                productId: checkup.id,
                quantity: checkup.quantity
            };
        }),
        cityId,
        delivery: { deliveryType: checkupDelivery.sendMethod, deliveryValue: checkupDelivery[checkupDelivery.sendMethod.toLowerCase()] },
        email,
        onlinePaymentData: getOnlinePaymentData({ cardCryptogramPacket, paymentEmail, phone, selectedPaymentType }),
        territory
    };
    yield request(CREATE_CHECKUP, [cartClient.createCheckupOrder, data], data);
}

function* preorderPay(action) {
    const saleAction = yield select(saleActionSelector);
    const territory = yield select(currentTerritorySelector);
    const selectedPaymentType = yield select(paymentTypeSelector);
    const { preorderId, cardCryptogramPacket, phone, paymentEmail } = action.meta;

    const loyaltyOption = getLoyaltyOption(saleAction);
    const onlinePaymentData = getOnlinePaymentData({ cardCryptogramPacket, paymentEmail, phone, selectedPaymentType });
    const data = {
        loyaltyOption,
        onlinePaymentData,
        preorderId,
        territory
    };

    yield request(PREORDER_PAY, [orderClient.preorderPay, data], data);
}
function* externalPreorderPay(action) {
    const saleAction = yield select(saleActionSelector);
    const territory = yield select(currentTerritorySelector);
    const selectedPaymentType = yield select(paymentTypeSelector);
    const { preorderId, cardCryptogramPacket, phone, paymentEmail } = action.meta;

    const loyaltyOption = getLoyaltyOption(saleAction);
    const onlinePaymentData = getOnlinePaymentData({ cardCryptogramPacket, paymentEmail, phone, selectedPaymentType });
    const data = {
        loyaltyOption,
        onlinePaymentData,
        preorderId,
        territory
    };

    yield request(EXTERNAL_PREORDER_PAY, [orderClient.externalPreorderPay, data], data);
}

function* cancel(action) {
    const data = action.meta;
    yield request(CANCEL, [orderClient.cancel, data], data);
}

export function* gmtOfficeAcceptedPurchase() {
    try {
        let param, orderData, orderProducts;
        param = PARAM.OFFICE;
        const cartAnalyzes = yield select(cartAnalyzesSelector);
        const { cost, costWithDiscount, currency } = yield select(commonCalculationDataSelector);
        const order = yield select((state) => state.orders.create.response);
        const discount = cost - costWithDiscount > 0 ? cost - costWithDiscount : '';
        orderData = yield new Order(order.id, costWithDiscount || cost, null, null, discount);
        orderProducts = yield cartAnalyzes.orderProducts.map((op) => new Product(op.product.article, op.product.name, null, null, op.cost, 1, op.discount));
        GTMService.purchase(PAGE.THANK_YOU, param, orderData, orderProducts);
        orderProducts = yield cartAnalyzes?.orderProducts.filter((item) => item.product.classifierData !== null && !item.product.classifierData?.service);

        window.dataLayer.push({
            Order_Id: order?.id,
            event: 'product_info',
            product_id_all:
                orderProducts.length === 1 ? orderProducts[0]?.product?.article : `${orderProducts.map((item) => `'${item.product.article}'`).join(', ')}`,
            revenue: cost?.toFixed(2)
        });
    } catch (e) {
        console.error(e);
    }
}

export default function* ordersSagas() {
    yield all([
        takeLatest(ORDERS_ALL.BASE, fetchOrders),
        takeLatest(CREATE.BASE, create),
        takeLatest(CREATE_CHECKUP.BASE, createCheckupOrder),
        takeLatest(CREATE.SUCCEEDED, gmtOfficeAcceptedPurchase),
        takeLatest(CREATE_REORDER.BASE, createReorder),
        takeLatest(PREORDER_PAY.BASE, preorderPay),
        takeLatest(EXTERNAL_PREORDER_PAY.BASE, externalPreorderPay),
        takeLatest(FETCH_ACCEPTED_INFO.BASE, fetchAcceptedInfo),
        takeLatest(CANCEL.BASE, cancel)
    ]);
}
