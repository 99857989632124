import { createRequestTypes } from 'redux/helpers';
import { ConfirmChangePatient, RequestChangePatient } from 'types/common';

export const FETCH_ALL = createRequestTypes('PATIENT/FETCH_ALL');
export const CREATE = createRequestTypes('PATIENT/CREATE');
export const SELECT = createRequestTypes('PATIENT/SELECT');
export const DELETE = createRequestTypes('PATIENT/DELETE');

export const FETCH_ONLINE_REGISTRATION_DATA = createRequestTypes('PATIENT/FETCH_ONLINE_REGISTRATION_DATA');
export const FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION = createRequestTypes('PATIENT/FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION');
export const FETCH_REGIONS = createRequestTypes('PATIENT/FETCH_REGIONS');
export const CLEAR_ONLINE_REGISTRATION_DATA = createRequestTypes('PATIENT/CLEAR_ONLINE_REGISTRATION_DATA');

export const ALLOW_HISTORY = createRequestTypes('PATIENT/ALLOW_HISTORY');
export const CONFIRM_HISTORY = createRequestTypes('PATIENT/CONFIRM_HISTORY');
export const DISALLOW_HISTORY = createRequestTypes('PATIENT/DISALLOW_HISTORY');

export const MAIN_PATIENT = createRequestTypes('PATIENT/MAIN_PATIENT');
export const CONFIRM_MAIN_PATIENT = createRequestTypes('PATIENT/CONFIRM_MAIN_PATIENT');

export const ALLOW_LOYALTY = createRequestTypes('PATIENT/ALLOW_LOYALTY');
export const CONFIRM_LOYALTY = createRequestTypes('PATIENT/CONFIRM_LOYALTY');
export const DISALLOW_LOYALTY = createRequestTypes('PATIENT_DISALLOW_LOYALTY');

export const ALLOW_DOCUMENTS = createRequestTypes('PATIENT/ALLOW_DOCUMENTS');
export const CONFIRM_DOCUMENTS = createRequestTypes('PATIENT/CONFIRM_DOCUMENTS');
export const DISALLOW_DOCUMENTS = createRequestTypes('PATIENT/DISALLOW_DOCUMENTS');

export const CLEAR_CONFIRMATIONS = createRequestTypes('PATIENT/CLEAR_CONFIRMATIONS');
export const CLEAR_PATIENTS = createRequestTypes('PATIENT/CLEAR_PATIENTS');

export const FETCH_PATIENT = createRequestTypes('PATIENT/FETCH_PATIENT');

export const SEND_TO_EPGU = createRequestTypes('PATIENT/SEND_TO_EPGU');
export const GET_EPGU_INZ_STATUSES = createRequestTypes('PATIENT/GET_EPGU_INZ_STATUSES');

export const PUBLISHED_PATIENT = createRequestTypes('PATIENT/PUBLISHED_PATIENT');

export const fetchPatients = (payload: { userId: string }) => FETCH_ALL.base(payload);
export const select = (payload: string) => SELECT.base(payload);

export const create = (payload: {
    userId: string;
    gender: string;
    lastName: string;
    firstName: string;
    middleName: string;
    birthday: string;
    mobilePhone: string;
    email: string;
    cityId: string;
}) => CREATE.base(payload);

export const disallowHistory = (payload: string) => DISALLOW_HISTORY.base(payload);
export const disallowLoyalty = (payload: string) => DISALLOW_LOYALTY.base(payload);
export const disallowDocuments = (payload: string) => DISALLOW_DOCUMENTS.base(payload);
export const deletePatient = (payload: string) => DELETE.base(payload);
export const fetchPatient = (payload: { patientId: string }) => FETCH_PATIENT.base(payload);
export const allowHistory = (payload: RequestChangePatient) => ALLOW_HISTORY.base(payload);
export const confirmHistory = (payload: ConfirmChangePatient) => CONFIRM_HISTORY.base(payload);
export const makeMainPatient = (payload: RequestChangePatient) => MAIN_PATIENT.base(payload);
export const confirmMainPatient = (payload: ConfirmChangePatient) => CONFIRM_MAIN_PATIENT.base(payload);
export const allowLoyalty = (payload: RequestChangePatient) => ALLOW_LOYALTY.base(payload);
export const confirmLoyalty = (payload: ConfirmChangePatient) => CONFIRM_LOYALTY.base(payload);
export const allowDocuments = (payload: RequestChangePatient) => ALLOW_DOCUMENTS.base(payload);
export const confirmDocuments = (payload: ConfirmChangePatient) => CONFIRM_DOCUMENTS.base(payload);
export const clearConfirmations = () => CLEAR_CONFIRMATIONS.base();
export const fetchPatientOnlineRegistrationData = () => FETCH_ONLINE_REGISTRATION_DATA.base();
export const fetchPatientPublicationStatus = () => PUBLISHED_PATIENT.base();
export const clearPatients = () => CLEAR_PATIENTS.base();
