//@ts-nocheck
import { Currency } from 'constants/currency';
import { SUPPORTED_TERRITORIES } from 'redux/cities/constants';

export function getCurrency(territory) {
    switch (territory) {
        case SUPPORTED_TERRITORIES.RUSSIA:
            return Currency.RUB;
        case SUPPORTED_TERRITORIES.BELARUS:
            return Currency.BYN;
        case SUPPORTED_TERRITORIES.KAZAKHSTAN:
            return Currency.KZT;
        case SUPPORTED_TERRITORIES.ARMENIA:
            return Currency.AMD;
        case SUPPORTED_TERRITORIES.KYRGYZSTAN:
            return Currency.KGS;
        default:
            return '';
    }
}
