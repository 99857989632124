import AlertWarning from 'components/alert/AlertWarning';
import Timer from 'components/timer/Timer';
import commonMessages from 'messages/CommonMessages';
import styles from 'page/auth/Common.module.css';
import React, { FC, Fragment, memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

type TProps = {
    error: any;
    show?: boolean;
    attemptsNotice?: any;
    timeToResend?: boolean;
    onError?: (message: string) => void;
};

const Error: FC<TProps> = ({ show = true, onError, error, attemptsNotice, timeToResend }) => {
    const { formatMessage } = useIntl();
    const [isTimerEnd, setIsTimerEnd] = useState(false);

    useEffect(() => {
        setIsTimerEnd(false);
    }, [timeToResend]);

    if ((!error?.message && !error?.errors && !error?.attempts) || !show) {
        return null;
    }

    const renderErrorMessage = (message: string) => {
        if (onError) {
            onError(message);
        }
        return message;
    };

    return (
        !isTimerEnd && (
            <AlertWarning
                show={error}
                content={
                    <Fragment>
                        <div>
                            {error.errors?.length
                                ? error.errors.map((error: any) => {
                                      return (
                                          <div key={error.code}>
                                              {renderErrorMessage(error.message)}
                                              <br />
                                          </div>
                                      );
                                  })
                                : renderErrorMessage(error.message)}

                            {timeToResend && error.timeToResend && (
                                <Timer className={styles.timer} seconds={error.timeToResend} onEnd={() => setIsTimerEnd(true)}>
                                    {({ formatted }: any) => {
                                        return formatMessage(commonMessages.afterTime, { time: formatted });
                                    }}
                                </Timer>
                            )}
                        </div>
                        {error.attempts && attemptsNotice && renderErrorMessage(formatMessage(attemptsNotice, { attempts: error.attempts }))}
                    </Fragment>
                }
                className={styles.alertWarning}
            />
        )
    );
};

export default memo(Error);
