//@ts-nocheck
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';
import queryString from 'query-string';

const CART_URL = `${BASE_URL}/cart`;

export const fetchOffices = (data) => {
    return apiClient.get(`${CART_URL}/offices?${queryString.stringify(data)}`);
};

export const validate = (data) => {
    const { officeId, ...request } = data;
    return apiClient.post(`${CART_URL}/offices/${officeId}/validate`, request);
};

export const calculateOffice = (data) => {
    const { officeId, ...request } = data;
    return apiClient.post(`${CART_URL}/offices/${officeId}/calculate`, request);
};

export const calculateOfficeWithAuth = (data) => {
    const { officeId, ...request } = data;
    return apiClient.post(`${CART_URL}/offices/${officeId}/calculate/authenticated`, request);
};

export const calculateCheckups = (data) => {
    const { cityId, ...request } = data;
    return apiClient.post(`${CART_URL}/checkups/cities/${cityId}/calculate`, request);
};

export const calculateCheckupsWithAuth = (data) => {
    const { cityId, ...request } = data;
    return apiClient.post(`${CART_URL}/checkups/cities/${cityId}/calculate/authenticated`, request);
};

export const createCheckupOrder = (data) => {
    const { cityId, ...request } = data;
    return apiClient.post(`${CART_URL}/checkups/cities/${cityId}/create-order`, request);
};

export const checkReorderAvailability = ({ officeId, orderId }) => {
    return apiClient.get(`${BASE_URL}/reorders?officeId=${officeId}&orderId=${orderId}`);
};

export const calculateReorder = (data) => {
    const { orderId, ...request } = data;
    return apiClient.post(`${CART_URL}/reorders/orders/${orderId}/calculate`, request);
};

export const calculatePaymentExternalPreorder = (data) => {
    const { preorderId, ...request } = data;
    return apiClient.post(`${BASE_URL}/external-preorders/${preorderId}/calculate`, request);
};

export const calculatePaymentPreorder = (data) => {
    const { preorderId, ...request } = data;
    return apiClient.post(`${BASE_URL}/preorders/${preorderId}/cart/calculate`, request);
};

export const shareCart = (data) => {
    const { ...request } = data;
    return apiClient.post(`${CART_URL}`, request);
};

export const fetchCartByCode = (data) => {
    return apiClient.get(`${CART_URL}/${data}/extended`);
};

export const checkProductsAvailable = (data) => {
    return apiClient.get(`${CART_URL}/offices/availability?${queryString.stringify(data)}`);
};
