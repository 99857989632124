import { useAppSelector } from 'app/Hooks';
import classNames from 'classnames/bind';
import noPatientPhoto from 'page/cart/confirmation/assets/no-patient-photo.svg';
import styles from 'page/cart/confirmation/patientBlock/PatientBlock.module.css';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { externalPatientSelector } from 'redux/cart/selectors';

import messages from './PatientBlockMessages';
const cx = classNames.bind(styles);

const ExternalPatientBlock = () => {
    const { formatMessage } = useIntl();
    const patient = useAppSelector(externalPatientSelector);
    const classNamePatient = cx({
        onlineRegistrationPatient: true,
        patient: true
    });

    return (
        <>
            {patient && (
                <div>
                    <h2 className={styles.patientBlockTitle}>{formatMessage(messages.title)}</h2>
                    <div className={classNamePatient} key={patient.id}>
                        <div className={styles.patientPhoto}>
                            <img src={noPatientPhoto} alt='' />
                        </div>
                        <div className={styles.patientInfo}>
                            <div className={styles.patientName}>
                                {patient.lastName} {patient.firstName} {patient.middleName}
                            </div>
                            <div className={styles.patientBirthday}>{patient.birthday}</div>
                            <div className={styles.patientEmail}>{patient.email}</div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default ExternalPatientBlock;
